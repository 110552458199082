export const environment = {
  production: false,
  apiHost: {
    caerus: `/api/caerus/`,
    athena: `/api/athena/`,
    odin: `/api/odin/`,
    coordinator: `/api/coordinator/`,
    bifrost: `/api/bifrost/`,
  },
  idleTimeout: 4 * 60 * 60,
  idleWarnTime: 1 * 60 * 60,
  autocompleteDebounceTime: 250,
  chironUrlsPostfix: 'dev.biomedata.io',
  tableauUrl: 'https://tableau.biomedata.io',
  auditorUrl: 'https://auditor.stag.biome.ws',
  supportDeskUrl:
    'https://biome-analytics.atlassian.net/servicedesk/customer/portals',
  gatewayUrl: 'https://bifrost.dev.biomedata.io',
  isDev: true,
  universalAnalyticsId: 'UA-193637549-1',
  enableAppCache: true,
  enableAnalytics: false,
  showDisclaimer: false,
  release: 'eclair',
  agGridLicenseKey:
    'Using_this_AG_Grid_Enterprise_key_( AG-049173 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Biome )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Biome )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( Biome )_need_to_be_licensed___( Biome )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 15 December 2024 )____[v2]_MTczNDIyMDgwMDAwMA==b872d883f041c2de3c03014e84dc0a6e',
  appInsightsConnectionString:
    'InstrumentationKey=18d9bfb6-6dc6-4aea-ab7f-3ecf9f25da45;IngestionEndpoint=https://westus-0.in.applicationinsights.azure.com/;LiveEndpoint=https://westus.livediagnostics.monitor.azure.com/',
  posthogKey: 'phc_4O87YTguoxeEJ3Sc3nrOVSLqs4DUPeSjPOu0AkETNGp',
  myDocumentsFeatureKey: 'showMyDocumentsOnHomepageDev',
  submissionStatusFeatureKey: 'showSubmissionStatusDev',
  riskAuditImprovementChartFeatureKey:
    'disableAuditImprovementOverviewChartDev',
  hideSimilaritySearchScores: false,
};
